<template>
  <v-app id="inspire">
    <v-navigation-drawer
      floating
      color="primary"
      :right="isRight"
      dark
      permanent
      expand-on-hover
      app
    >
     <v-list>
    

        <v-list-item v-if="user != null" link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ user.username }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
     
      <v-divider></v-divider>
      <v-list nav dark color="primary" dense>
      
        <v-list-item
          v-for="item in navGroups.filter(each => each.authorization == true)"
          :key="item.text"
          :to="item.to"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dark color="primary" dense>
            <v-list-group color="#fffff"  v-for="(navGroup, index) in reportMenu.filter(each => each.authorization == true)"  :to="navGroup.to" :key="index">
                <template v-if="navGroup.children.length != 0" v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>{{ navGroup.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ navGroup.text }}</v-list-item-title>
                </template>
                <v-list-item  link 
                    v-for="(navItem, navItemIndex) in navGroup.children.filter(each => each.authorization == true)" 
                    :key="navItemIndex" 
                    :to="navItem.to"
                    dense>
                    <v-list-item-icon class="ml-5">
                        <v-icon>{{ navItem.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ navItem.text }}</v-list-item-title>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>

    <v-app-bar dark color="primary" flat app>
      <v-toolbar-title>Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn large @click="changeRTL" text>
        {{ $t("lang") }}
      </v-btn>
      <v-btn @click="logOut" icon>
        <v-icon large>mdi-export</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon large>mdi-cog-outline</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <snackbar-alert />
  </v-app>
</template>


<script>
import { mapMutations, mapState } from 'vuex';
import SnackbarAlert from "../components/gen/SnackbarAlert.vue";
export default {
  name: "Layout",
  components: {
    SnackbarAlert,
  },
  data() {
    return {
      drawer: null,
    };
  },
  computed: {

   ...mapState(['user']),
    flag() {
      return this.isRight ? '<flag iso="sa" />' : '<flag iso="us" />';
    },
    isRight() {
      return this.$vuetify.rtl;
    },
    reportMenu() {
      return [
        {
          text: this.$t("Reports"),
          icon: "mdi-chart-box",
          authorization: this.auth,
          to: "",
          children:[ 
            {
              text: this.$t("company reports"),
              icon: "mdi-chart-bar-stacked",
              authorization: this.auth,
              to: "/reports",
            },
            {
              text: this.$t("schemes reports"),
              icon: "mdi-chart-arc",
              authorization: this.auth,
              to: "/schemes-reports",
            }
          ]
        },
      ]
    },
    navGroups() {
      return [
        {
          text: this.$t("home"),
          icon: "mdi-home",
          authorization: true,
          to: "/",
        },
        {
          text: this.$t("Policy management"),
          icon: "mdi-badge-account-horizontal",
          authorization: true,
          to: "/policies",
        },
        {
          text: this.$t("Notification"),
          icon: "mdi-bell",
          authorization: false,
          to: "/notifications",
        },
        {
          text: this.$t("Insurance Company Management"),
          icon: "mdi-bank",
          authorization: this.auth,
          to: "/companies",
        },
        {
          text: this.$t("schemes"),
          icon: "mdi-notebook-outline",
          authorization: true,
          to: "/schemes",
        },
        {
          text: this.$t("User management"),
          icon: "mdi-account-group",
          authorization: this.auth,
          to: "/users",
        },
      ];
    },
    auth() {
     return this.user != null ? this.user.type == 'Super-Admin' ?  true : false : false;
    }
  },
  methods: {
    ...mapMutations(['clearToken']),
    changeRTL() {
      this.$vuetify.rtl = !this.$vuetify.rtl;
      this.$vuetify.rtl
        ? (this.$i18n.locale = "ar")
        : (this.$i18n.locale = "en");
    },
    logOut() {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
this.clearToken();
this.$router.push('/login');
    }
  },
};
</script>

<style>
.v-list-item--link:before {
  background-color: "#ff8c00";
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>